import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import documentToReactComponents from "../components/RichTextRenderer"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const PostNavigator = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0 1rem;
  padding-top: 1rem;
  border-top: 1px solid #827463;
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.contentfulBlogPost
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.title}
        description={post.intro.childMarkdownRemark.rawMarkdownBody}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: rhythm(0.6),
              color: "#596E79",
            }}
          >
            {post.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.publishDate}
          </p>
        </header>
        <section>
          {documentToReactComponents({ richTextJson: post.body.json })}
        </section>
      </article>

      <PostNavigator>
        <span>
          {previous && (
            <Link to={`/${previous.slug}`} rel="prev">
              ←&nbsp;{previous.title}
            </Link>
          )}
        </span>
        <span>
          {next && (
            <Link to={`/${next.slug}`} rel="next">
              {next.title}&nbsp;→
            </Link>
          )}
        </span>
      </PostNavigator>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      intro {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      title
      body {
        json
      }
      intro {
        childMarkdownRemark {
          html
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
    }
  }
`
